
import Form from './components/form';
import Menu from './components/menu';
import Produit from './components/produit';
import Caroussel from './components/caroussel';
import Footer from './components/footer'
import Propos from './components/propos';
import Login from './components/login';
import Details_produits from './components/details_produits';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Burger from './components/burger';

const router= createBrowserRouter([
  
  {
    path :'/produit',
    element: <Produit/>
  },
  {
    path :'/form',
    element: <Form/>
  },
  {
    path :'/propos',
    element: <Propos/>
  },
  {
    path :'/login',
    element: <Login/>
  },
  {
    path :'/',
    element: <Caroussel/>
  },
  {
    path :'/details_produits',
    element: <Details_produits/>
  },

]);
function App() {
  return (
    <div className=''>
      <Menu />
      <RouterProvider router={router}/>
      <Footer/>
    </div>
  );


}

export default App;
