import React, { Component } from "react";
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import Produit from "./produit";
class Caroussel extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (
            <div className="container bg-secondary text-light d-block pb-2 pt-1">
                <MDBCarousel showIndicators showControls fade>
                    <MDBCarouselItem itemId={1}>
                        <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg' className='d-block w-100' alt='...' />
                        <MDBCarouselCaption>
                            <h5>First slide label</h5>
                            <p>Bienvenue sur notre Boutique JayTech.</p>
                        </MDBCarouselCaption>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId={2}>
                        <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg' className='d-block w-100' alt='...' />
                        <MDBCarouselCaption>
                            <h5>Second slide label</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </MDBCarouselCaption>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId={3}>
                        <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg' className='d-block w-100' alt='...' />
                        <MDBCarouselCaption>
                            <h5>Third slide label</h5>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                        </MDBCarouselCaption>
                    </MDBCarouselItem>
                </MDBCarousel>
                
                <Produit/>
            </div>

        )
    }

}
export default Caroussel;