import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/form.css';
class Propos extends Component {

    render() {
        const state = {
            name: 'dmb@dev.com'
        };
        const hundleChange = (e) => {
            console.log(e.target.value);
        }
        return (
            <div className="container col-md-10 col-sm-6 bg-light d-block pb-2 mb-5" >
                    <div className="row bg-light pt-2 mt-3">
                        <div className="col">
                            <h1 className="bg-dark text-light text-center">A propos</h1>
                        </div>
                    </div>
                    <div className="row bg-light ">
                        <div className="col">
                            <p>Bienvenue sur notre plateforme !</p>
                            <p>BLoc est une entreprise de vente et location de vehicule,
                                specialisee dans ce domaine avec une expertise de 5ans
                                maintenant.</p>
                            <p>Notre equipe est principalement constituees de jeunes dynamiques,
                                accueillant et tres professionels.</p>
                            <p>Pour tous vos ceremonies allant de bapteme, mariage, colloques,
                                conferences, galas etc , n'hesitez pas a nous contacter et vous
                                serez satisfait.</p>
                        </div>
                    </div>
                    <div className="row bg-light m-auto">
                        <h1 className="bg-dark text-light text-center">Notre equipe</h1>
                        <div className="row d-flex">
                            <div className="col-sm-6">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <img src="images/boss.jpg" width="100%" className="h-50"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        {<img src="images/image0.png" width="100%" height="50%" ></img>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <img src="images/mon_logo.png" width="100%" className="h-5"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <img src="images/mon_logo.png" width="100%" className="h-50"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
        );

    }
}
export default Propos;