import React, { Component, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/form.css';
class Produit extends Component {

    constructor() {
        super();
        this.state = {
            tissus: [
                {
                    name: "Bazin riche",
                    description: "bazin de qualité qui vous offre un caractèere elegant dans votre style vestimentaire: un bazin pour toute la famille!",
                    urls: "images/African-Bazin-Riche.jpg",
                    btn: "Voir plus",
                    id: "1",
                    prix: "metre 3500 frs"
                },
                {
                    name: "Wax",
                    text: "Ce textile riche en couleur et motif est aujourd’hui utilisé dans le revêtement décoratifs, ainsi que dans la mode.\
                        Originaire du Ghana, le pagne revêt une signification particulière. Véritable tissu mini hollandais imprimé top qualité 100% pur COTON Motifs vifs et éclatants.",
                    urls: "images/wax.jpg",
                    btn: "Voir plus",
                    id: "2",
                    prix: "5 metres à 5000 frs"
                },
                {
                    name: "Tissus de Pantalon Classique",
                    text: "Tissus de Pantalon Classique de 1m50 chacun– Bleu et Noir pour vêtements de Travail, Blazer/Vestes et Pantalons\
                            Achetez notre tissu pour habillement, haute couture et mode",
                    urls: "images/super-100.jpeg",
                    btn: "Voir plus",
                    id: "3",
                    prix: "2000frs"
                },
                {
                    name: "Bazin riche",
                    text: "bazin de qualité qui vous offre un caractèere elegant dans votre style vestimentaire: un bazin pour toute la famille!",
                    urls: "images/African-Bazin-Riche.jpg",
                    btn: "Voir plus",
                    id: "4",
                    prix: "metre 3500 frs"
                },
                {
                    name: "Wax",
                    text: "Ce textile riche en couleur et motif est aujourd’hui utilisé dans le revêtement décoratifs, ainsi que dans la mode.\
                    Originaire du Ghana, le pagne revêt une signification particulière. Véritable tissu mini hollandais imprimé top qualité 100% pur COTON Motifs vifs et éclatants.",
                    urls: "images/wax.jpg",
                    btn: "Voir plus",
                    id: "5",
                    prix: "5 metres 5000 frs"
                },
                {
                    name: "Tissus de Pantalon Classique",
                    text: "Tissus de Pantalon Classique de 1m50 chacun– Bleu et Noir pour vêtements de Travail, Blazer/Vestes et Pantalons\
                            Achetez notre tissu pour habillement, haute couture et mode.\
                            Idéal pour la confection de vêtements, pantalon, veste, robe... et d'accessoires de mode",
                    urls: "images/super-100.jpeg",
                    btn: "Voir plus",
                    id: "6",
                    prix: "2000frs"
                },

            ],
            proprio: [
                {
                    "nom": "Diop",
                    "prenom": "Samba",
                    "age": 35
                },
                {
                    "nom": "Diop",
                    "prenom": "Moussa",
                    "age": 35
                },
                {
                    "nom": "Diop",
                    "prenom": "Abdou",
                    "age": 35
                },
                {
                    "nom": "Diop",
                    "prenom": "Awa",
                    "age": 35
                },

            ]

        };

    }

    componentDidMount() {

        // fetch("http://localhost/Mes%20r%C3%A9alisations/Backend-gestionsautomobile/api/tissus.php")
        //     .then(response => response.json())
        //     .then(v => this.setState({ tissus: v }))


    };

    render() {
        return (
            <div className="container bg-light mt-2 m-auto d-block" >
                <div className="row">
                    <h1 className="text-light bg-primary text-center">Nos produits</h1>
                    {this.state.tissus.map(tissu =>
                    (<div className="card col-lg-4 col-md-6 col-sm-12 p-3 ml-2 mb-1">
                        <div className="card-header"> {tissu.name}</div>
                        <div className="card-body">
                            <img src={tissu.urls} className="w-100" height={200} alt="image" />
                            <p>Description: {tissu.description} {tissu.name}</p>
                            <div className="btn btn-primary float-end d-bottom" id={tissu.id} onClick={
                                ()=>{
                                localStorage.setItem("mine",tissu.id);
                                document.location.href="/details_produits";
                            }}>{tissu.btn}</div>
                        </div>
                    </div>)
                    )
                    }
                </div>
            
                <div className="row mt-2 mb-5">
                    <img src="images/flyers.png" alt="img" />
                </div>
            </div>

        );

    }
}
export default Produit;