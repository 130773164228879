import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Burger from "./burger";
class Menu extends Component {
    render() {
        const logo = '../logo512.png';
        const newLocal = "header m-auto pl-2";
        const disp = "p-2";
        return (
            <div className="container-fluid bg-primary">
                <div className="row bg-primary">
                    <div className="menu col-lg-12 col-md-12 col-sm-6 mt-2 d-flex">
                        <a href="/" className="text-warning">JayTech</a>
                        <ul className="newLocal" slot="end">
                            <li><a href="/">Acceuil</a></li>
                            <li ><a className={disp} href="/produit">Produit</a></li>
                            <li ><a className={disp} href="/propos">A_propos</a></li>
                            <li ><a className={disp} href="/form">Contact</a></li>
                        </ul>
                        <a href="/login" className="float-end">Login </a>
                    </div>
                </div>

            </div>
        );
    }
}
export default Menu;