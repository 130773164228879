import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
class Footer extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='text-light text-center bg-dark fixed-bottom'>Designed by &#169;<span className='text-primary'>BTech</span> 2024</div>
            </div>

        )
    }
}
export default Footer;