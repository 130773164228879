import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
export default class form extends Component {
    render() {
        return (
            <div className='container-fluid w-75 m-auto mt-2'>
                <div className="row col-md-6 col-sm-12 mt-5 m-auto">
                    <h3 className='text-center bg-warning text-light text-small pb-1'>Contact</h3>
                    <form className="form-horizontal m-auto" action="/action_page.php">
                        <div className="form-group">
                            <label className="control-label col-sm-2" htmlFor="email">Email:</label>
                            <div className="col-sm-10">
                                <input type="email" className="form-control" id="email" placeholder="Enter email" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-2" htmlFor="pwd">Message:</label>
                            <div className="col-sm-10">
                                <textarea className='form-control w-100'></textarea>
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <div className="col-sm-offset-2 col-sm-10">
                                <button type="submit" className="btn btn-primary float-end">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
